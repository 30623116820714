<template>
  <div class="content bg8">
    <div class="middled">
      <div class="basehero">
        <h1>About Us</h1>
        <h2 class="section">
          We use technology to <span style="color:#BF00FF;">simplify work and accelerate goals</span>.
        </h2>
        <p>
          Our mission is to empower leaders with AI-powered tools so you can stay focused, and your team can accelerate. 
          We use technology to simplify things like employee training and learning, equipping new hires, interns, and reskilling teams to navigate critical business challenges effectively. 
          The Foclarity platform provides customizable AI-enhanced learning frameworks, and essential tools to ensure teams are growing, focused and aligned on key objectives.
        </p>
        <h2>
          <span style="color:#BF00FF;">Foclarity is much more</span> than software. 
        </h2>
        <p>
          We are a team of early-career marketing interns, and seasoned professionals with a wealth of experience, guiding enterprise companies, startups, and small businesses through major challenges, accelerating growth and success.
        </p>
        <p>
          Marketing, especially digital marketing, is a key area where we make the most impact.
          Our suite of marketing services help you grow faster and more effectively.
          Learn about our <a href="/who-we-serve">Marketing Services</a> or <a href="/contact">schedule a call</a> to discuss your unique situation.
        </p>
        <h1>Meet Your Team</h1>
        <section>
          <h2 class="section">
            Julia Padilla, Marketing Associate
          </h2>
          <img src="@/assets/julia-image.jpeg" alt="Julia Padilla" style="float:left;margin:0px 15px 0px 0;border-radius:50%;width:140px;height:140px;border:1px solid #ccc;">
          <p>
            Julia is your first point of contact and the leader of our intern team.
          </p>
          <p>
            Recently graduated with an Associates in Business Administration and currently pursuing a Bachelor's degree at SJSU.
            Passionate about marketing and equipped with both academic knowledge and internship experience.
          </p>
          <p>
            LinkedIn: <a href="https://www.linkedin.com/in/julia-padilla-a77aba286/" target="_blank">Julia Padilla</a>.
          </p>
          
          <h2 class="section">
            Drew Becks, Marketing Intern
          </h2>
          <img src="@/assets/drew-image.jpeg" alt="Drew Becks" style="float:left;margin:0px 15px 0px 0;border-radius:50%;width:140px;height:140px;border:1px solid #ccc;">
          <p>
            Drew is in our Spring 2025 Marketing Intern Program and will be on your marketing team.
          </p>
          <p>
            A third year, business operations student at San Jose State University. 
            I played junior college baseball at Cuesta and Gavilan College where I received my accociates degree. 
            Junior college baseball allowed me to understand what it takes to be successful on a team.
          </p>
          <p>
            LinkedIn: <a href="https://www.linkedin.com/in/andrew-r-becks-545b08201/" target="_blank">Andrew R. (Drew) Becks</a>.
          </p>
          
          <h2 class="section">
            Jalen Panopio, Marketing Intern
          </h2>
          <img src="@/assets/jalen-image.jpeg" alt="Jalen Panopio" style="float:left;margin:0px 15px 0px 0;border-radius:50%;width:140px;height:140px;border:1px solid #ccc;">
          <p>
            Jalen is in our Spring 2025 Marketing Intern Program and will be on your marketing team.
          </p>
          <p>
            A creative and hardworking design student with Computer Science experience. 
            I'm experienced in collaborating with others through teamwork across departments and self taught applications/skills such as Adobe Illustration, Python, and Java.
          </p>
          <p>
            LinkedIn: <a href="https://www.linkedin.com/in/jalenpanopio/" target="_blank">Jalen Panopio</a>.
          </p>
          
          <h2 class="section">
            Addison Rowe, Marketing Intern
          </h2>
          <img src="@/assets/addison-image.jpeg" alt="Addison Rowe" style="float:left;margin:0px 15px 0px 0;border-radius:50%;width:140px;height:140px;border:1px solid #ccc;">
          <p>
            Addison is in our Spring 2025 Marketing Intern Program and will be on your marketing team.
          </p>
          <p>
            A student at SJSU, Addison is passionate about business and eager to learn and contribute to your success.
          </p>
          <p>
            LinkedIn: <a href="https://www.linkedin.com/in/addison-rowe-387306315/" target="_blank">Addison Rowe</a>.
          </p>

          <h2 class="section">
            Denisse Tungol, Marketing Intern
          </h2>
          <img src="@/assets/denisse-image.jpeg" alt="Denisse Tungol" style="float:left;margin:0px 15px 0px 0;border-radius:50%;width:140px;height:140px;border:1px solid #ccc;">
          <p>
            Denisse is in our Spring 2025 Marketing Intern Program and will be on your marketing team.
          </p>
          <p>
            A student at SJSU, Denisse is passionate about business, marketing, and especially web design and gaming.
            She is a quick learner and eager to contribute to your marketing success.
          </p>
          <p>
            LinkedIn: <a href="https://www.linkedin.com/in/denisse-tungol-929927248/" target="_blank">Denisse Tungol</a>.
          </p>

          <h2 class="section">
            Michael Ashley, CEO
          </h2>
          <img src="@/assets/mash-michael-ashley.png" alt="Mash Michael Ashley, MBA" style="float:left;margin:0px 15px 0px 0;border-radius:50%;width:140px;height:140px;border:1px solid #ccc;">
          <p>
            Michael Ashley, AKA Mash, is the CEO and Founder of Foclarity. With over 25 years experience developing enterprise software solutions and advising startups,
            Mash has developed the first AI-enhanced marketing platform designed to help startups and small businesses grow and scale—faster.
          </p>
          <p>
            He is also the Startup Incubator Launch Director at San José State University and Adjunct Professor in the College of Business, 
            where his expertise spans Marketing, Operations, International Business, Entrepreneurship, and Strategy. 
            As the Launch Director for the SpartUp Incubator at SJSU, Mash has played a pivotal role in incubating over 30 startup teams, 
            significantly impacting the entrepreneurial landscape. 
            His real-world experience as an entrepreneur and advisor, marked by four successful exits, 
            lends practical insights into his teaching and mentorship. 
          </p>
          <p> 
            His work in the field of marketing, coupled with a rich educational background in business and technology, 
            position him as a thought leader in AI-enhanced marketing and entrepreneurship. 
            Mash's approach to business is characterized by a blend of academic rigor and real-world pragmatism, 
            making him a sought-after speaker and expert in the startup community.
          </p>
          <p>
            LinkedIn: <a href="https://www.linkedin.com/in/mashley" target="_blank">Michael (Mash) Ashley</a>.
          </p>
        </section>

        <section>
          <p>
            We are hiring. Connect with <a href="https://www.linkedin.com/company/foclarity/" target="_blank">Foclarity on LinkedIn</a> to learn more.
          </p>
        </section>

        <div style="background:#f0f8ff; border:1px solid #ccc;padding:40px;margin: 50px 0 ;">
          <h2 style="text-align: center;">Need help getting started?</h2>
          <p style="text-align: center;">
            Schedule a call and learn how it works.
          </p>
          <p style="text-align: center;">
            <a href="/contact">
                <button class="wide" style="background: #000;"> Schedule a Call </button>
            </a>
          </p>
        </div>

        <div v-show="false">
          <section>
            <h2>Our Story</h2>
            <p>
              We are a team of management consultants who share a vision to revolutionize how companies adapt to change and achieve sustainable growth.
              We are dedicated to helping business leaders accelerate through top-level challenges with enormous impact. 
              Foclarity is our system of frameworks and software, proven to drive growth, retention and customer success with focus, clarity and alignment.
            </p>
          </section>

          <section>
            <h2>Our Expertise</h2>
            <p>
              At Foclarity, we take pride in over 40 years combined experience guiding enterprise companies and startups through major challenges, 
              accelerating growth and success. 
              Our team of seasoned professionals brings a wealth of knowledge and a proven track record of successful transformations in diverse industries.
            </p>
          </section>

          <section>
            <h2>Customer Focus</h2>
            <p>
              Our clients are at the heart of everything we do. 
              At Foclarity, we strive to understand our customers' unique needs and tailor our solutions to address their specific business transformation requirements. 
              Customer success and satisfaction drive us to continuously innovate and deliver exceptional results.
            </p>
          </section>
          
          <section>
            <h2>Company Culture</h2>
            <p>
              At Foclarity, we foster a culture of collaboration, innovation, and continuous learning. 
              Our diverse team of experts shares a passion for creating cutting-edge software solutions that empower leaders to lead successful transformations. 
              We believe that our people are the driving force behind our achievements.
            </p>
          </section>
          
          <section>
            <h2>Social Responsibility</h2>
            <p>
              Giving back to the community is an integral part of our corporate ethos. 
              At Foclarity, we actively engage in various philanthropic initiatives and support causes that drive positive change. 
              Our commitment to social responsibility extends beyond our software, making a meaningful impact on society.
            </p>
          </section>
          
        </div>

      </div>
    </div>    
  </div>
</template>

<script>
import { isLoggedIn } from "../utils/auth";


export default {
  title: 'About Us | Foclarity',
  name: "WhoWeAre",
  mounted: function() {
    if (isLoggedIn()) {
      // this.$router.push("dashboards");
    }
  },
  methods: {
    isLoggedIn() {
      return isLoggedIn();
    }
  },
  components: {},
  data () {
      return {
      }
  },
  created () {
  }
};
</script>

<style lang="scss" scoped>

.heroblue {
  background: linear-gradient(110deg, #00b2ee 60%, #007cee 60%);
  height: 320px;
  margin: -20px -20px 20px -20px;
  border-radius: 4px 4px 0 0;
  @media only screen and (max-width: 440px) {
    border-radius: 0px 0px 0 0;
  }
  display: flex;
  align-items: center;
  justify-content: center;
  & h1 {
    text-align: center;
    color: #fff;
    font-size: 45px;
    max-width: 600px;
    @media (max-width: 415px) {
      font-size: 36px;
      padding: 20px;
    }
  }
  & a {
    text-decoration: none;
    color: #fff;
    & .signupbox {
      text-align: center;
      padding: 8px;
      font-size: 24px;
      font-weight: bold;
      background: #ff7f24;
      width: 300px;
      border-radius: 10px;
      border: 1px solid #fff;
      margin: auto;
    }
  }
}

.row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

.column {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
  padding: 10px;
  min-width: 200px;
  & .homecard {
    text-align: center;
    padding: 20px;
    border-radius: 4px;
    background: rgba(0, 178, 238, 0.1);
    height: 100%;
    & .cardicon {
      width: 100px;
      height: 100px;
      margin: auto;
      & img {
        width: 80px;
      }
    }
    & .h3 {
      font-size: 23px;
      line-height: 32px;
      color: #CC0099;
      text-align: left;
      @media (max-width: 400px) {
        font-size: 20px;
      }
    }
  }
}

.homequote {
  text-align: center;
  font-size: 24px;
  line-height: 32px;
  font-style: italic;
  color: #666;
  max-width: 600px;
  margin: 20px auto;
}
</style>

